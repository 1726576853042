import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Stars from "./Stars"
import Button from "@components/Button/Button"
import classnames from "classnames"

const AllSocialReviews = ({
  reviews,
  language,
  heading,
  buttonHref,
  buttonText,
  isColumn,
  colorBack,
  animation,
}) => {
  const animate = animation ? "fade-up" : ""

  const wrapperClasses = classnames({
    "home-section": heading,
    "ratings-review top-down": true,
    "color-back": colorBack,
  })

  const data = useStaticQuery(graphql`
    query SocialQuery {
      googleJson {
        data {
          rating
          platform
          platformId
          code
          url
          totalReviews
        }
      }
      facebookJson {
        data {
          rating
          platform
          platformId
          code
          url
          totalReviews
        }
      }
    }
  `)

  let google = data.googleJson.data
  let facebook = data.facebookJson.data

  if (!reviews) {
    reviews = [
      { name: "Walnut Creek", ...google },
      { name: "", ...facebook },
    ]
  }

  return (
    <section className={wrapperClasses}>
      <div className="container" data-aos={animate} data-aos-duration="1200">
        {heading && (
          <div className="columns">
            <div className="column is-2"></div>
            <div className="column is-20">
              <h2 className="has-text-centered h3-style">{heading}</h2>
            </div>
            <div className="column is-2"></div>
          </div>
        )}
        <div
          className="rating-reviews home-reviews columns is-centered iconless big-rating"
          style={{
            maxWidth: "1000px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div className="column is-2"></div>
          <div
            className="all-reviews-container"
            style={{ flexDirection: isColumn ? "column" : "row" }}
          >
            {reviews &&
              reviews.map((review, i) => (
                <div className="featured-social">
                  <div>
                    <span
                      role="presentation"
                      style={{
                        fontSize: "0.875rem",
                        lineHeight: "1.71",
                        color: "#6d6e6f",
                        display: "block",
                        marginBottom: review.name ? "-4px" : "20px",
                        fontWeight: "bold",
                        fontFamily: "Montserrat",
                      }}
                    >
                      {review.name ? review.name + " Office" : ""}
                    </span>
                    <a
                      aria-label="open link in new browser tab"
                      title="View all of our reviews"
                      className="link-wrapper"
                      href={review.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h3>
                        {review.platform === "googleMyBusiness"
                          ? "Google"
                          : "Facebook"}
                      </h3>
                      <div className="total-reviews">
                        ({review.totalReviews})
                      </div>
                    </a>
                  </div>
                  <div>
                    <a
                      aria-label="open link in new browser tab"
                      title="View all of our reviews"
                      href={review.url}
                      className="reviews-link"
                      target="_blank"
                    >
                      <div className="review-icons">
                        <Stars rating={review.rating} />
                      </div>
                    </a>
                    <a
                      aria-label="open link in new browser tab"
                      title="View all of our reviews"
                      href={review.url}
                      className="reviews-link"
                      target="_blank"
                    >
                      <span className="reviews-number">
                        {review.rating === 5 ? "5.0" : review.rating}
                      </span>
                    </a>
                  </div>
                </div>
              ))}
          </div>
          <div className="column is-2"></div>
        </div>
      </div>

      {buttonHref && (
        <div className="columns has-text-centered">
          <div className="column">
            <Button contained buttonText={buttonText} href={buttonHref} />
          </div>
        </div>
      )}
    </section>
  )
}

export default AllSocialReviews
